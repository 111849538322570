import React from "react";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMembersByDepartment } from "../../../../redux/actions/Members.actions";

const Members = ({ departmentId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMembersByDepartment(departmentId));
  }, []);
  const { members } = useSelector((state) => state.members);
  return (
    <>
      {members &&
        members.map((member) => (
          <div className="instructor-item">
            <Row>
              <Col md="4">
                <div className="instructor-img">
                  <img
                    src={
                      member.image
                        ? member.image
                        : `/assets/images/instructor-1.jpg`
                    }
                    alt={member.name}
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col md="8">
                <div className="instructor-content">
                  <div className="instructor-box">
                    <div className="top-content d-flex justify-content-between">
                      <div className="instructor-name">
                        <h6>{member.name}</h6>
                        <p>Senior Lecturer</p>
                      </div>
                      <div className="instructor-social">
                        <ul className="social list-unstyled list-inline">
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + "/"}>
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + "/"}>
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + "/"}>
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + "/"}>
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="instructor-desk">
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Quae perferendis delectus voluptate reiciendis animi
                        nisi nemo tenetur sequi cum laudantium sit totam libero
                        quasi ducimus accusantium numquam eaque.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
    </>
  );
};

export default Members;
