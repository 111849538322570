import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Datas from "../data/blog/home-blog.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/homeBlog.js";
import { getNews } from "../redux/actions/news.action";
import { URL } from "../redux/actions";
import moment from "moment";

const HomeBlog = () => {
  const dispatch = useDispatch();
  const { newss, loading } = useSelector((state) => state.news);
  useEffect(() => {
    dispatch(getNews());
  }, [loading]);

  return (
    newss && (
      <Styles>
        {/* Blog Area */}
        <section className="home-blog-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="sec-title text-center">
                  <h4>{Datas.secTitle}</h4>
                </div>
              </Col>
              {newss.map((data, i) => (
                <Col md="6" key={i}>
                  <div className="blog-post">
                    <Row>
                      <Col lg="6" md="12">
                        <div className="blog-img">
                          <Link to={"/events/" + data._id}>
                            <img
                              src={`
                                ${URL}${data.image}`}
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </Col>
                      <Col lg="6" md="12" style={{ direction: "rtl" }}>
                        <div className="blog-content">
                          <div className="content-box">
                            <div className="top-content d-flex">
                              <div className="blog-date text-center">
                                <p>{moment(data.updatedAt).format("MMM Do")}</p>
                              </div>
                              <div className="blog-title">
                                <h6>
                                  <Link to={"/events/" + data._id}>
                                    {data.title}
                                  </Link>
                                </h6>
                              </div>
                            </div>
                            <div className="blog-desk">
                              <p
                                className="text-justify"
                                dangerouslySetInnerHTML={{
                                  __html: data.discription,
                                }}
                              ></p>
                              <ul className="list-unstyled list-inline">
                                <li>
                                  <i className="las la-user"></i>{" "}
                                  {data.author.name}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </Styles>
    )
  );
};

export default HomeBlog;
