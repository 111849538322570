import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../../../redux/actions";
import { getSubjects } from "../../../../redux/actions/Subjects.action";

const Plans = ({ departmentId }) => {
  const semester = [
    { num: 'متطلب جامعي', label: 'متطلب جامعي' },
    { num: 'المقررات العامة والأساسية ', label: 'المقررات العامة والأساسية ' },
    { num: 'المقررات الإلزامية التخصصية ', label: 'المقررات الإلزامية التخصصية ' },
    { num: 'المقررات الاختيارية التخصصية', label: 'المقررات الاختيارية التخصصية' },
    { num: 'المقررات الاختيارية غير التخصصية ', label: 'المقررات الاختيارية غير التخصصية ' },
    { num: 'المقررات المساندة والداعمة ', label: 'المقررات المساندة والداعمة ' },

    
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjects(departmentId));
  }, [departmentId]);
  const { subjects } = useSelector((state) => state.subjects);

  const sumUnits  = (category) =>{
    const foundSub =  subjects && subjects.filter(s => s.category === category)
    return foundSub.reduce((accumulator, currentValue) => accumulator + currentValue.unit, 0);

  }

  return (
    <>
      {semester.map((s) => (
        <div className="course-element">
          <h5>{s.label}</h5>
          <div className="course-item">
            <div className="course-content show">
              <ul className="list-unstyled">
                {subjects &&
                  subjects.map((subject) => (
                    <>
                      {subject.category === s.num && (
                        <li>
                          {/* <img
                            src={`${URL}${subject.image}`}
                            alt={subject.subjectname}
                            width="70"
                            className="m-2 rounded"
                          /> */}
                          <h6 className="m-3 d-inline-flex ww30">
                            {subject.subjectname}
                          </h6>

                          <p className="lecture-title m-3 d-inline">
                            عدد وحدات :<h6 className="m-3 d-inline">{subject.unit}</h6> 
                          </p>
                          <p className="lecture-title m-3 d-inline">
                            رمز المادة :<h6 className="m-3 d-inline">{subject.subjectCode}</h6> 
                          </p>
                          <p className="lecture-title m-3 d-inline">
                          الأسبقية :<h6 className="m-3 d-inline">{subject.precedence}</h6> 
                          </p>
                          
                        </li>
                      )}
                    </>
                  ))}
                  <li><p className="lecture-title m-3 d-inline ww30">  مجموع الوحدات :<h6 className="m-3 d-inline ww30">{sumUnits(s.label)}</h6>  </p></li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Plans;
