import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/about/About";
import Events from "./pages/events/Events";
import EventsDetails from "./pages/events/EventsDetails";
import CourseDetails from "./pages/courses/CourseDetails";
import ContactUs from "./pages/contact/Contact";
import Instructors from "./pages/instructor/Instructors";

import ScrollToTop from "./helper/ScrollToTop";
import { GlobalStyle } from "./components/common/styles/global.js";
import './css.css'
const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/events" exact component={Events} />
        <Route path="/events/:id" exact component={EventsDetails} />
        <Route path="/instructors" exact component={Instructors} />
        <Route path="/contact" exact component={ContactUs} />
        <Route path="/:Url" exact component={CourseDetails} />
      </Switch>
    </Router>
  );
};

export default App;
