import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "moment/locale/ar-ma";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import { Styles } from "./styles/event.js";
import { URL } from "../../redux/actions";
import { getNews } from "../../redux/actions/news.action";

const Events = () => {
  const dispatch = useDispatch();
  const { newss, loading } = useSelector((state) => state.news);
  useEffect(() => {
    dispatch(getNews());
  }, [loading]);
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="أخر الاخبار و المقالات" />

        {/* Events Area */}
        <section className="event-page-area" style={{ direction: "rtl" }}>
          <Container>
            <Row>
              <Col lg="11" md="12">
                {newss.map((data, i) => (
                  <div className="event-box" key={i}>
                    <Row>
                      <Col xl="3" lg="4" md="0">
                        <div className="event-img">
                          <Link to={`/events/${data._id}`}>
                            <img
                              src={`${URL}${data.image}`}
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </Col>
                      <Col xl="9" lg="8" md="12" className="text-right">
                        <div className="event-content">
                          <div className="content-box">
                            <Row>
                              <Col md="12">
                                <div className="event-title">
                                  <h6>
                                    <Link to={`/events/${data._id}`}>
                                      {data.title}
                                    </Link>
                                  </h6>
                                </div>
                                <div className="event-time-location">
                                  <ul className="list-unstyled list-inline">
                                    <li className="list-inline-item">
                                      <i className="las la-clock"></i>{" "}
                                      {moment(data.updatedAt).format(
                                        "MMM Do YY"
                                      )}
                                    </li>
                                    <li className="list-inline-item">
                                      <i className="las la-map-marker"></i>{" "}
                                      {data.author.name}
                                    </li>
                                  </ul>
                                </div>
                                <div className="event-desc">
                                  <p
                                    className="text-justify"
                                    dangerouslySetInnerHTML={{
                                      __html: data.discription,
                                    }}
                                  ></p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
              </Col>
              <Col md="12" className="text-center mt-5">
                <Pagination />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default Events;
