import React, { useState } from "react";
import Datas from "../data/free-course/free-course.json";
import { Container, Row, Col } from "react-bootstrap";
import Timer from "react-compound-timer";
import { Styles } from "./styles/freeCourse.js";
import { useDispatch, useSelector } from "react-redux";
import { getResult } from "../redux/actions/Student.action";
const FreeCourse = () => {
  const [sid, setsid] = useState("");

  const dispatch = useDispatch();
  const { result } = useSelector((state) => state.students);
  const formSubmit = (e) => {
    e.preventDefault();
    if (sid !== "") {
      dispatch(getResult(sid));
    }
  };

  return (
    <Styles>
      {/* Free Course */}
      <section className="free-course-area">
        <Container>
          <Row>
            <Col md="7">
              <div className="course-text text-center">
                {result && (
                  <>
                    <h4
                      className={
                        result.status === "ناجح"
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      نتيجة {result.name} : {result.status}
                    </h4>
                    <p>
                      بتقدير {result.overallRating} و بنسبة نجاح{" "}
                      {result.graduationRate}
                    </p>
                  </>
                )}
              </div>
              <div className="countdown-timer">
                {result && (
                  <>
                    <>
                      {result.d1 && (
                        <p>
                          <span>{result.d1}</span>
                          {result.m1}
                        </p>
                      )}
                    </>
                    <>
                      {result.d2 && (
                        <p>
                          <span>{result.d2}</span>
                          {result.m2}
                        </p>
                      )}
                    </>
                    <>
                      {result.d3 && (
                        <p>
                          <span>{result.d3}</span>
                          {result.m3}
                        </p>
                      )}
                    </>
                    <>
                      {result.d4 && (
                        <p>
                          <span>{result.d4}</span>
                          {result.m4}
                        </p>
                      )}
                    </>
                  </>
                )}
              </div>

              <div className="countdown-timer mt-3">
                {result && (
                  <>
                    <>
                      {result.d5 && (
                        <p>
                          <span>{result.d5}</span>
                          {result.m5}
                        </p>
                      )}
                    </>
                    <>
                      {result.d6 && (
                        <p>
                          <span>{result.d6}</span>
                          {result.m6}
                        </p>
                      )}
                    </>
                    <>
                      {result.d7 && (
                        <p>
                          <span>{result.d7}</span>
                          {result.m7}
                        </p>
                      )}
                    </>
                    <>
                      {result.d8 && (
                        <p>
                          <span>{result.d8}</span>
                          {result.m8}
                        </p>
                      )}
                    </>
                    <>
                      {result.d9 && (
                        <p>
                          <span>{result.d9}</span>
                          {result.m9}
                        </p>
                      )}
                    </>
                  </>
                )}
              </div>
            </Col>
            <Col md="5">
              <div
                className="register-form text-center"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${Datas.formBackground})`,
                }}
              >
                <div className="form-box">
                  <h4 className="title">الحصول علي نتيجة</h4>
                  <p className="desc">للحصول علي النتيجة ادخل رقم القيد</p>
                  <form id="form3" className="form">
                    <p className="form-control">
                      <input
                        type="text"
                        name="sid"
                        className="text-right"
                        value={sid}
                        onChange={(e) => setsid(e.target.value)}
                        placeholder="ادخل رقم القيد"
                      />
                      <span className="input-msg3"></span>
                    </p>

                    <button onClick={formSubmit}>إرسال</button>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default FreeCourse;
