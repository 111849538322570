import React, { useEffect } from "react";
import Datas from "../../data/event/details.json";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Timer from "react-compound-timer";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import PopularCourse from "./../courses/components/PopularCourse";
import CourseTag from "./../courses/components/CourseTag";
import FooterTwo from "../../components/Footer";
import { Styles } from "./styles/eventDetails.js";
import { getNews, getOneNews } from "../../redux/actions/news.action";
import Spinner from "../../components/common/Spinner";
import moment from "moment";
import { URL } from "../../redux/actions";
import ImageGallery from "../../components/ImageGallery";
import Popularevents from "../courses/components/Popularevents";

const EventDetails = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneNews(match.params.id));
  }, []);
  const { news, newss } = useSelector((state) => state.news);
  const { departments } = useSelector((state) => state.departments);
  return !news ? (
    <Spinner />
  ) : (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper event-details-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title={` الاخبار و الاعلانات - ${news.title} `} />

        {/* Event Details Area */}
        <section className="event-details-area" style={{ direction: "rtl" }}>
          <Container>
            <Row>
              <Col lg="9" md="8" sm="12">
                <div className="event-details-content">
                  <div className="heading">
                    <h4>{news.title}</h4>
                  </div>
                  <div className="event-icon">
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <i className="las la-clock"></i>{" "}
                        {moment(news.upadatAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </li>

                      <li className="list-inline-item">
                        <i className="las la-user"></i> {news.author.name}
                      </li>
                    </ul>
                  </div>
                  <div className="event-details-banner">
                    <img
                      src={`${URL}${news.image}`}
                      alt={news.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="event-details-overview">
                    <h5>تفاصيل الخبر </h5>
                    <p
                      dangerouslySetInnerHTML={{ __html: news.discription }}
                    ></p>
                  </div>
                  {news.imagegalary && (
                    <div className="event-details-speaker">
                      <h5>معرص الصور</h5>
                      <ImageGallery images={news.imagegalary} />
                    </div>
                  )}

                  <div className="pre-nxt-event">
                    <Row>
                      {newss &&
                        newss.slice(1, 2).map((news) => (
                          <Col md="6">
                            <div className="next-prev-item prev-event d-flex">
                              <div className="prev-img">
                                <img
                                  src={`${URL}${news.image}`}
                                  alt={news.title}
                                />
                              </div>
                              <div className="prev-text">
                                <p>
                                  <Link to={`/events/${news._id}`}>
                                    {news.title}
                                  </Link>
                                </p>
                                <span>
                                  {" "}
                                  {moment(news.upadatAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </span>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="4" sm="12">
                <div className="event-details-sidebar">
                  <Row>
                    <Col md="12">
                      <Popularevents />
                    </Col>
                    <Col md="12">
                      <PopularCourse departments={departments} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

export default EventDetails;
