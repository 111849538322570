import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Styles } from "./styles/contact.js";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 32.76083939673342,
      lng: 12.73933322721253,
    },
    zoom: 20,
  };

  render() {
    return (
      <Styles>
        {/* Google Map */}
        <div className="google-map-area">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyATY4Rxc8jNvDpsK8ZetC7JyN4PFVYGCGM",
            }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
          >
            <AnyReactComponent
              lat={32.76083939673342}
              lng={12.73933322721253}
              text="كلية طب و جراحة الفم و الاسنان"
            />
          </GoogleMapReact>
        </div>
      </Styles>
    );
  }
}

export default GoogleMap;
