import React, { Component } from "react";
import Datas from "../data/footer/footer.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerOne.js";
class Footer extends Component {
  render() {
    return (
      <Styles>
        {/* Copyright Area */}
        <section className="copyright-area">
          <Container>
            <Row>
              <Col md="6">
                <div className="copy-text">
                  <p>
                    &copy; {new Date().getFullYear()} |{" "}
                    <a
                      href={process.env.PUBLIC_URL + "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      جامعة دروب اساريا الاهلية
                    </a>
                  </p>
                </div>
              </Col>
              <Col md="6" className="text-right">
                <ul className="social list-unstyled list-inline">
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/profile.php?id=100074471298171">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>

          {/* Back To Top */}
          <BackToTop />
        </section>
      </Styles>
    );
  }
}

export default Footer;
