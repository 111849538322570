import React, { useEffect } from "react";
import Datas from "../data/faq-event/faq-event.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/faqEvent.js";

function FaqEvent() {
  useEffect(() => {
    const accordionButton = document.querySelectorAll(".accordion-button");
    accordionButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "accordion-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "accordion-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      {/* Faq & Event */}
      <section className="event-faq-area">
        <Container style={{ textAlign: "center" }}>
          <div>
            <h3 className="m-4">الهيكل التنطيمي للجامعة</h3>
            <img
              src="assets/images/h.jpg"
              alt="الهيكل التنطيمي  للجامعة "
              style={{ width: "100%" }}
            />
          </div>
        </Container>
      </section>
    </Styles>
  );
}

export default FaqEvent;
