import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";

const TabBox = ({ alumni, graduationYears }) => {
  return (
    <Styles>
      {/* Tab Box Area */}
      <section className="tab-section" style={{ direction: "rtl" }}>
        <Container>
          <h2 className="text-center m-5">خريجي الجامعة</h2>
          <Tab.Container defaultActiveKey="go1">
            <Row>
              <Col lg="3" md="4">
                <Nav className="flex-column">
                  {graduationYears &&
                    graduationYears.map((gra) => (
                      <Nav.Item>
                        <Nav.Link eventKey={gra.title}>
                          خريجي سنة{" "}
                          <i className="las la-arrow-left">{gra.title}</i>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>
              </Col>
              <Col lg="9" md="8">
                <Tab.Content>
                  {graduationYears &&
                    graduationYears.map((gra) => (
                      <Tab.Pane eventKey={gra.title}>
                        <ul className="list-unstyled check-list">
                          {alumni &&
                            alumni.map((a) => (
                              <>
                                {a.graduationYear === gra.title && (
                                  <li>
                                    <i class="fas fa-user-graduate"></i>
                                    {a.name} - {a.nationality} -{" "}
                                    {a.graduationRate} - {a.overallRating}
                                  </li>
                                )}
                              </>
                            ))}
                        </ul>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
    </Styles>
  );
};

export default TabBox;
