import axios from "axios";
import { GET_SUBJECTS, ERROR_SUBJECT, URL } from "../actions";

// GET CONFERANCES
export const getSubjects = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/subjects/${id}`);
    dispatch({
      type: GET_SUBJECTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SUBJECT,
      payload: { msg: error.response, status: error.response },
    });
  }
};
