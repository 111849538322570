import React, { Component } from "react";
import Datas from "../data/gallery/gallery.json";
import { Container, Row, Col } from "react-bootstrap";
import ModalImage from "react-modal-image";
import { Styles } from "./styles/imageGallery.js";
import { URL } from "../redux/actions";

const ImageGallery = ({ images }) => {
  return (
    <Styles>
      {/* Campus Tour */}
      <section className="gallery-area">
        <Container fluid>
          <Row>
            {images &&
              images.map((data, i) => (
                <Col md="3" sm="6" className="padding-fix" key={i}>
                  <div className="gallery-box">
                    <ModalImage
                      small={`${URL}${data}`}
                      large={`${URL}${data}`}
                      alt=""
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default ImageGallery;
