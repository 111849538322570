import React, { useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import ReviewForm from "./components/ReviewForm";
import PopularCourse from "./components/PopularCourse";
import CourseTag from "./components/CourseTag";
import FooterTwo from "../../components/Footer";
import { Styles } from "./styles/course.js";
import { getoneDepartment } from "../../redux/actions/department.action";
import Spinner from "../../components/common/Spinner";
import { URL } from "../../redux/actions";
import Plans from "./components/plan/Plans";
import Members from "./components/members/members";
const CourseDetails = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getoneDepartment(match.params.Url));
  }, [match]);
  useEffect(() => {
    const courseButton = document.querySelectorAll(".course-button");
    courseButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "course-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "course-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });
  const { department, departments } = useSelector((state) => state.departments);
  return !department ? (
    <Spinner />
  ) : (
    <div className="main-wrapper course-details-page">
      {/* Header 2 */}
      <HeaderTwo />

      {/* Breadcroumb */}
      <BreadcrumbBox title={`قسم ${department.title}`} />

      <Styles>
        {/* Course Details */}
        <section className="course-details-area" style={{ direction: "rtl" }}>
          <Container>
            <Row>
              <Col lg="9" md="8" sm="12">
                <div className="course-details-top">
                  <div className="course-details-banner">
                    <img
                      src={`${URL}${department.image}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="course-tab-list">
                    <Tab.Container defaultActiveKey="overview">
                      <Nav className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="overview">عن القسم</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="curriculum">
                            الخطة التدريسية
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="instructor">
                            اعضاء هيئة التدريس
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="review">Reviews</Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="overview" className="overview-tab">
                          <div
                            className="course-desc"
                            dangerouslySetInnerHTML={{
                              __html: department.description,
                            }}
                          ></div>
                        </Tab.Pane>
                        {/* ********************************************** */}
                        <Tab.Pane
                          eventKey="curriculum"
                          className="curriculum-tab"
                        >
                          <div className="course-curriculum">
                            <h5>الخطة الدراسية</h5>
                            <p>
                              من آهداف برنامج قسم {department.title} تقديم منهج
                              متخصص مستوف لمواصفات الجودة في مجال تخصص القسم
                              للطلبة بما يطور من قدراتهم النظرية و النقدية و
                              الابداعية
                            </p>
                          </div>
                          <Plans departmentId={department._id} />
                        </Tab.Pane>

                        {/* ************************************* */}
                        <Tab.Pane
                          eventKey="instructor"
                          className="instructor-tab"
                        >
                          <h5>أعضاء هيئة التدريس بالقسم </h5>
                          <Members departmentId={department._id} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" sm="12">
                <div className="single-details-sidbar">
                  <Row>
                    <Col md="12">
                      <div className="course-details-feature">
                        <h5 className="title">بيانات القسم</h5>
                        <ul className="list-unstyled feature-list">
                          <li>
                            <i className="las la-calendar"></i> بداية الدراسة:{" "}
                            <span>Aug 21, 2020</span>
                          </li>
                          <li>
                            <i className="las la-clock"></i> مدة الدراسة:{" "}
                            <span>4 السنوات</span>
                          </li>
                          <li>
                            <i className="las la-globe"></i> لغة الدراسة:{" "}
                            <span>{department.languege}</span>
                          </li>
                          <li>
                            <i className="las la-globe"></i> عدد المواد:{" "}
                            <span>24</span>
                          </li>
                          <li>
                            <i className="las la-sort-amount-up"></i>
                            مشروع تخرج: <span>نعم</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md="12">
                      <PopularCourse
                        departments={departments}
                        id={department._id}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};

export default CourseDetails;
