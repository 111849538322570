import { GET_ADVS, ERROR_ADV, GET_ADV } from "../actions";

const inialState = {
  advs: [],
  adv: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ADVS:
      return {
        ...state,
        advs: payload,
        loading: false,
      };
    case GET_ADV:
      return {
        ...state,
        adv: payload,
        loading: false,
      };
    case ERROR_ADV:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
