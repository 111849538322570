import {
  GET_STUDENTS,
  ERROR_STUDENT,
  FILTER_STUDENTS,
  GET_RESULT,
} from "../actions";

const inialState = {
  alumni: [],
  result: null,
  students: [],
  student: null,
  filtered: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STUDENTS:
      return {
        ...state,
        students: payload.filter((al) => !al.alumni),
        alumni: payload.filter((al) => al.alumni === "true"),
        loading: false,
      };
    case GET_RESULT:
      return {
        ...state,
        result: payload,
        loading: false,
      };
    case FILTER_STUDENTS:
      return {
        ...state,
        filtered: state.students.filter((student) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            student.name.match(regex) ||
            student.department.match(regex) ||
            student.sid.toString().match(regex)
          );
        }),
      };
    case ERROR_STUDENT:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
