import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import Search from "./common/Search";
import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/headerTwo.js";
import { getDepartment } from "../redux/actions/department.action";
import { URL } from "../redux/actions";

const HeaderTwo = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
  }, []);
  const { departments } = useSelector((state) => state.departments);
  return (
    <Styles>
      {/* Topbar 2 */}
      <section className="top-bar2">
        <Container>
          <Row>
            <Col lg="7" md="9">
              <div className="bar-left">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="las la-phone"></i>637-43-68 (091)
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-envelope"></i>info@asarya.edu.ly
                  </li>
                  <li className="list-inline-item">
                    <i className="las la-map-marker"></i>الزاوية - شارع صلاح
                    الدين
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="5" md="3">
              <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-social">
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item"></li>
                </ul>

                <ul className="list-unstyled list-inline sidebar-button">
                  <li className="list-inline-item nav-item side-box">
                    <Sidebar />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Logo Area 2 */}
      <section className="logo-area2">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to="/">
                  <img src="/assets/images/univ.png" alt="" width="150" />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item  active">
                    <Link className="nav-link" to="/">
                      الرئيسية
                    </Link>
                  </li>
                  <li className="nav-item  active">
                    <Link className="nav-link" to="/about">
                      عن الجامعة
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      الاقسام <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled ">
                      {departments &&
                        departments.map((department) => (
                          <li className="nav-item" key={department._id}>
                            <Link className="nav-link " to={department.Url}>
                              {department.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/instructors"}
                    >
                      أعضاء هيئة التدريس{" "}
                    </Link>
                  </li>

                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/events"}
                    >
                      الاخبار و الاعلانات
                    </Link>
                  </li>
                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/contact"}
                    >
                      اتصل بنا
                    </Link>
                  </li>
                </ul>
                <div className="search-box">
                  <Search />
                </div>
                <div className="apply-btn">
                  <a
                    href={`${URL}signup`}
                    target="_blank"
                  >
                    <i className="las la-clipboard-list"></i>سجل الان
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
      <StickyMenu />

      {/* Mobile Menu */}
      <MobileMenu departments={departments} />
    </Styles>
  );
};

export default HeaderTwo;
