import { GET_MEMBERS, ERROR_MEMBER } from "../actions";

const inialState = {
  members: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: payload,
        loading: false,
      };
    case ERROR_MEMBER:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
