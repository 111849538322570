import React from "react";
import { Link } from "react-router-dom";
import { URL } from "../../../redux/actions.js";
import { Styles } from "../styles/popularCourse.js";

const PopularCourse = ({ departments, id }) => {
  const otherDepartments = id
    ? departments.filter((d) => d._id !== id)
    : departments;
  return (
    <Styles>
      {/* Popular Course */}
      <div className="popular-course">
        <h5> أقسام مقترحة</h5>
        <div className="popular-items">
          {otherDepartments.map((data, i) => (
            <div className="item-box d-flex" key={i}>
              <div className="item-img">
                <Link to={data.Url}>
                  <img src={`${URL}${data.image}`} alt={data.title} />
                </Link>
              </div>
              <div className="item-content">
                <p className="title">
                  <Link to={data.Url}>{data.title}</Link>
                </p>{" "}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Styles>
  );
};

export default PopularCourse;
