import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {
  render() {
    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section" style={{ direction: "rtl" }}>
          <Container>
            <h2 className="text-center m-5">الأهداف الاستراتيجية</h2>
            <Tab.Container defaultActiveKey="go1">
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="go1">
                        <i className="las la-arrow-right"></i> الهدف الرئيسي
                        الأول
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="go2">
                        <i className="las la-arrow-right"></i> الهدف الرئيسي
                        الثاني
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="go3">
                        <i className="las la-arrow-right"></i> الهدف الرئيسي
                        الثالث
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="go1">
                      <h4 className="tab-title">
                        تقديم البرامج التعليمية في تخصصات القانون، إدارة
                        الاعمال، المحاسبة، علوم الحاسوب، اللغة الإنجليزية وفق
                        معايير الجودة ومتطلباتها وبما يلبي احتياجات سوق العمل.
                      </h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check "></i>
                          مواكبة تطوير البرامج التعليمية وتحسينها من أجل إعداد
                          وتأهيل خريجي الجامعة للإيفاء بمتطلبات سوق العمل.
                        </li>
                        <li>
                          <i className="fa fa-check "></i>
                          العمل بجدية للحصول على الاعتماد المؤسسي وكذلك الاعتماد
                          البرامجي لجميع البرامج التعليمية التي تقدمها الجامعة.
                        </li>
                        <li>
                          <i className="fa fa-check "></i>
                          تنمية وتطوير والكوادر البشرية بالجامعة من خلال تنفيذ
                          برامج تدريبية هادفة.
                        </li>
                        <li>
                          <i className="fa fa-check "></i>
                          تقديم الخدمات الطلابية المساندة وذلك لدعم عمليتي
                          التعليم والتّعلم.
                        </li>
                        <li>
                          <i className="fa fa-check "></i>
                          استخدام الوسائل التعليمية والتقنيات الحديثة في تقديم
                          برامج التعليم والتعلم.
                        </li>
                        <li>
                          <i className="fa fa-check "></i>
                          زيادة البرامج التعليمية المقدمة في الجامعة.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="go2">
                      <h4 className="tab-title">
                        الاهتمام بالدراسات والبحوث العلمية للإسهام في إحداث
                        تنمية مجتمعية.{" "}
                      </h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check "></i> توجيه الدراسات
                          والبحوث العلمية لخدمة المجتمع.
                        </li>
                        <li>
                          <i className="fa fa-check "></i> دعم وتشجيع البحث
                          العلمي وإتاحة فرص التعاون مع المؤسسات التعليمية
                          والمراكز البحثية.
                        </li>
                        <li>
                          <i className="fa fa-check "></i> الاهتمام بالمكتبة
                          الالكترونية والمصادر التعليمية المفتوحة بما في ذلك
                          التعليم الالكتروني.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="go3">
                      <h4 className="tab-title">
                        تفعيل دور الجامعة للمساهمة في التنمية المجتمعية
                        والمسؤولية الأخلاقية والاجتماعية.
                      </h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check "></i> إعداد الدراسات
                          العلمية التي تسهم في حل مشاكل البيئة المحيطة والمجتمع.
                        </li>
                        <li>
                          <i className="fa fa-check "></i> التوسع في أنشطة
                          الجامعة التي تدعم علاقتها بالمجتمع والبيئة.
                        </li>
                        <li>
                          <i className="fa fa-check "></i> الالتزام بميثاق
                          أخلاقيات المهنة ضمن إطار المسؤولية الأخلاقية
                          والاجتماعية.
                        </li>
                        <li>
                          <i className="fa fa-check "></i>تقديم خدمات لتنمية
                          الجامعة والمجتمع المحلى.
                        </li>
                        <li>
                          <i className="fa fa-check "></i> بناء جسور التواصل مع
                          الخريجين ومتابعتهم.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="go4">
                      <h4 className="tab-title">تحسين وتعزيز قدرات الخريجين</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check "></i>الهدف التنفيذي الأول:
                          تحقيق الجودة المطلوبة من خلال الاعتماد وقبول الطلاب
                          المتميزين وتنوع شروط الالتحاق بالكلية.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>الهدف التنفيذي الثاني:
                          تعزيز دور الكلية في إنتاج ونقل المهارات للخريجين
                          (Continuous Education).
                        </li>
                        <li>
                          <i className="fa fa-check"></i> الهدف التنفيذي الثالث:
                          تمكين طلبة الكلية من اكتساب المعارف والمهارات العملية.
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="go5">
                      <h4 className="tab-title">
                        تطوير أداء البحث العلمي وخدمة المجتمع والبيئة
                      </h4>

                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check "></i>أسلوب تعليم إبداعي
                          حديث بإستجدام احدث وسائل ووسائط التعليم .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>الهدف التنفيذي الأول:
                          إعداد آلية تنظيمية للبحث العلمي.
                        </li>
                        <li>
                          <i className="fa fa-check"></i> الهدف التنفيذي الثاني:
                          تقديم الخبرات والاستشارات لخدمة قضايا المجتمع والبيئة.{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          الهدف التنفيذي الثالث: توظيف البحوث العلمية للمساهمة
                          في حلحلت مشاكل المجتمع والبيئة.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          الهدف التنفيذي الرابع: إعداد وتجهيز مجلة علمية لنشر
                          البحوث العلمية.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>الهدف التنفيذي الخامس:
                          إكساب طلبة الكلية المعارف والمهارات ريادة الأعمال.
                        </li>
                      </ul>
                    </Tab.Pane>

                    <Tab.Pane eventKey="vision">
                      <h4 className="tab-title">رؤيتنا</h4>
                      <p className="tab-desc">
                        بناء مؤسسة قوية مستقلة وشريك أساسي في عملية بناء وتدريب
                        وتطوير الكوادر المحلية بمؤسسات الدولة العامة والخاصة .
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">قيمنا</h4>

                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>العمل بإتقان وحرفية.
                          الإيمان بروح الفريق لتحسين الأداء العام .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>المشاركة الفعالة مع كل
                          الأطراف الشريكة في عملية التنمية والتطوير
                        </li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="research">
                      <h4 className="tab-title">اهدافنا</h4>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>لتعاون واكتساب الخبرة
                          مع المراكز والشركات ذات الصلة.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>المشاركة في الأنشطة
                          وورش العمل والمؤتمرات المحلية والدولية فيما يخص
                          البرامج التدريبية و التأهيلية والتنموية.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>توفير البيئة والمناخ
                          الملائم لتدريب وتطوير الكوادر المحلية للمساهمة في
                          الرفع من أداء أنشطتها وبرامجها داخل ليبيا.
                        </li>
                      </ul>
                      <br />
                      <h4 className="tab-title">الأهداف الاستراتيجية </h4>
                      <h5>
                        الهدف الأول : تهيئة الإطار العام لعمل الشركة ويشمل الاتي
                        :-
                      </h5>
                      <ul className="list-unstyled check-list">
                        <br />
                        <li>
                          {" "}
                          <i className="fa fa-check"></i>
                          البناء المؤسسي وفقا للمعايير الليبية والدولية ، وتعزيز
                          فاعلية الشركة من خلال ربط العمل والبناء المؤسسي
                          بالمهام التي أسست من أجلها{" "}
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          اعداد نظام تقييم الأداء الوظيفي والجودة وفقا للوصف
                          الوظيفي المعتمد لكافة التقسيمات الإدارية ووظائفها
                          الإدارية والفنية.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          وضع خطة استراتيجية متكاملة لاستمرارية الأعمال ،
                          والمشاركة في إيجاد الحلول المجال العمل في المؤسسات من
                          خلال تقديم الاستشارات وتذليل الصعاب ، امام المؤسسات
                          العامة والخاصة .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          و الاهتمام بتعزيز التوعية والتثقيف بعمل المؤسسات من
                          خلال الأتي :
                          <br />
                          اعداد الخطط الاستراتيجية ، والبدء بالتنفيذ من خلال
                          التواصل مع الجهات ذات العلاقة ، او التطوير المستمر
                          والدمج العملي ، كذلك إعداد برامج خاصة لكافة الفئات
                          العمرية للمواطنين بالمجتمع الليبي خصوصا فئة الشباب
                          وتعزيز الثقافة بالتوجه مباشرة الى متطلبات سوق العمل من
                          خلال البرامج التدريبية الفنية والحرفية .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          إقامة ورش عمل وندوات ومؤتمرات التوعية والتثقيف وبناء
                          القدرات والتوجه شوق العمل .
                        </li>
                      </ul>
                      <br />
                      <h5>
                        الهدف الثاني : تنظيم وتنمية وتطوير الكوادر المحلية من
                        خلال إيجاد شراكات دولية حقيقية تضمن تحسين جودة أدائها .
                        إطار العمل في الشراكات الدولية : -
                      </h5>
                      <ul className="list-unstyled check-list">
                        <br />
                        <li>
                          {" "}
                          <i className="fa fa-check"></i>
                          التواصل مع بعض المراكز والشركات الدولية العاملة في ذات
                          الاختصاص والوقوف عند الفرص والتحديات التي تواجه عمل
                          المؤسسات المحلية للاستفادة من التدريب الخارجي .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          متابعة البرامج والمشاركات التي تقوم بها المراكز
                          والشركات الدولية مع القطاع العام المتمثل في الوزارات
                          والهيئات الوطنية لزيادة الخبرة ونقل التكنولوجيا وجودة
                          التدريب والتطوير .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          طرح وتطوير أفكار المراكز ذات العلاقة للاستفادة من
                          خبرتها وايجاد شركاء دوليين لتنفيذ اعمالنا وتطوير الخطط
                          المستقبلية .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          .تطوير وسائل التعامل والتواصل مع المراكز الدولية
                          والمحلية بمنظومات الكترونية واستخدام التكنولوجيا
                          الحديثة في إدارة الأعمال .
                        </li>
                        <li>
                          <i className="fa fa-check"></i>
                          رفع مستوي العاملين بالشركة وخصوصا إدارة الجودة لكي
                          يسنح لهم العمل بكل كفاءة وإنتاج .
                        </li>
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default TabBox;
