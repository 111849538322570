import { combineReducers } from "redux";

import departments from "./reducers/department.reduser";
import news from "./reducers/news.reduser";
import members from "./reducers/members.reduser";
import subjects from "./reducers/subjects.reduser";
import advs from "./reducers/advs.reduser";
import graduationYears from "./reducers/graduationYear.reduser";
import students from "./reducers/students.reduser";
export default combineReducers({
  departments,
  news,
  members,
  subjects,
  graduationYears,
  students,
  advs,
});
