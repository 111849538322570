import axios from "axios";
import { GET_STUDENTS, URL, ERROR_STUDENT, GET_RESULT } from "../actions";

// GET CONFERANCES
export const getStudents = () => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/students`);
    dispatch({
      type: GET_STUDENTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: { msg: error.response, status: error.response },
    });
  }
};

// Get one News
export const getResult = (sid) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${URL}/api/students/getresult`, { sid });
    dispatch({
      type: GET_RESULT,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_STUDENT,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
