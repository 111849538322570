import { GET_DEPARTMENTS, ERROR_DEPARTMENT, GET_DEPARTMENT } from "../actions";

const inialState = {
  departments: [],
  department: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: payload,
        loading: false,
      };
    case GET_DEPARTMENT:
      return {
        ...state,
        department: payload,
        loading: false,
      };
    case ERROR_DEPARTMENT:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
