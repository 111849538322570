import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Styles } from "./styles/stickyMenu.js";
import { getDepartment } from "../../redux/actions/department.action.js";

function StickyMenu() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".sticky-menu");

      if (window.scrollY > 160) {
        stickyMenu && stickyMenu.classList.add("sticky");
      } else {
        stickyMenu && stickyMenu.classList.remove("sticky");
      }
    });
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartment());
  }, []);
  const { departments } = useSelector((state) => state.departments);

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className="sticky-menu">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img src="assets/images/univ.png" alt="" width="150" />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/"}
                    >
                      الرئيسية
                    </Link>
                  </li>
                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/about"}
                    >
                      عن الجامعة
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      الاقسام <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled ">
                      {departments &&
                        departments.map((department) => (
                          <li className="nav-item" key={department._id}>
                            <Link className="nav-link " to={department.Url}>
                              {department.title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/instructors"}
                    >
                      أعضاء هيئة التدريس{" "}
                    </Link>
                  </li>

                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/events"}
                    >
                      الاخبار و الاعلانات
                    </Link>
                  </li>
                  <li className="nav-item  active">
                    <Link
                      className="nav-link"
                      to={process.env.PUBLIC_URL + "/contact"}
                    >
                      اتصل بنا
                    </Link>
                  </li>
                </ul>
                <div className="apply-btn">
                  <a href={`${URL}signup`} target="_blank">
                    <i className="las la-clipboard-list"></i>سجل الان
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu;
