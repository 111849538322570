import axios from "axios";
import { GET_NEWSS, ERROR_NEWS, URL, GET_NEWS } from "../actions";

// GET Newss
export const getNews = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${URL}/api/news`);
    dispatch({
      type: GET_NEWSS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_NEWS,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};

// Get one News
export const getOneNews = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${URL}/api/news/${id}`);
    dispatch({
      type: GET_NEWS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_NEWS,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
