import { GET_SUBJECTS, ERROR_SUBJECT } from "../actions";

const inialState = {
  subjects: [],
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: payload,
        loading: false,
      };
    case ERROR_SUBJECT:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
