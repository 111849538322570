import React, { useEffect } from "react";
import Datas from "../data/course/slider.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/courseSlider.js";
import { useSelector, useDispatch } from "react-redux";
import { getAdvs } from "../redux/actions/advs.action";
import { URL } from "../redux/actions";

const AdvsSlider = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdvs());
  }, []);
  const { advs } = useSelector((state) => state.advs);
  const settings = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 30,
    watchSlidesVisibility: true,
    pagination: {
      el: ".slider-dot.text-center",
      clickable: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <Styles>
      {/* Course Slider */}
      <section className="course-slider-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <h4>{Datas.secTitle}</h4>
              </div>
            </Col>
            <Col md="12" className="course-slider">
              <Swiper {...settings}>
                {advs.map((data, i) => (
                  <div className="course-item" key={i}>
                    <Link to={`/advs/${data._id}`}>
                      <div
                        className="course-image"
                        style={{
                          backgroundImage: `url(${URL}${data.image})`,
                        }}
                      >
                        <div className="author-img d-flex">
                          <div className="img">
                            <img src={`${URL}${data.image}`} alt="" />
                          </div>
                          <div className="title">
                            <p>{data.author.name}</p>
                            <span>{data.authorCourses}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="course-content">
                      <h6 className="heading">
                        <Link to={`/advs/${data._id}`}>{data.title}</Link>
                      </h6>
                      <p className="desc">{data.discription}</p>
                      <div className="course-face d-flex justify-content-between">
                        <div className="duration">
                          <p>
                            <i className="las la-clock"></i>120
                          </p>
                        </div>
                        <div className="student">
                          <p>
                            <i className="las la-chair"></i>60
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default AdvsSlider;
