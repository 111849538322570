import React, { Component } from "react";
import Datas from "../../data/instructor/instructor.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Pagination from "./../../components/Pagination";
import FooterTwo from "../../components/Footer";
import { Styles } from "./styles/instructor.js";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMembers } from "../../redux/actions/Members.actions";
import { useSelector } from "react-redux";
import { URL } from "../../redux/actions";

const Instructor = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMembers());
  }, []);
  const { members } = useSelector((state) => state.members);
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper instructor-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="آعضاء هيئة التدريس" />

        {/* Instructor Area */}
        <section className="instructor-area">
          <Container>
            <Row>
              {members.map((data, i) => (
                <Col lg="3" md="4" sm="6" key={i}>
                  <div className="instructor-item">
                    <img
                      src={
                        data.image
                          ? `${URL}${data.image}`
                          : "/assets/images/avatar.png"
                      }
                      alt=""
                      className="img-fluid"
                    />
                    <div className="img-content text-center">
                      <h5>{data.name}</h5>
                      <p>{data.spisials}</p>
                      <ul className="list-unstyled list-inline">
                        {data.facebook && (
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + data.facebook}>
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        )}
                        {data.twitter && (
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + data.twitter}>
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        )}

                        {data.youtube && (
                          <li className="list-inline-item">
                            <a href={process.env.PUBLIC_URL + data.youtube}>
                              <i className="fab fa-youtube"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </Col>
              ))}

              <Col md="12" className="text-center">
                <Pagination />
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer 2 */}
        <FooterTwo />
      </div>
    </Styles>
  );
};

export default Instructor;
