import axios from "axios";
import { GET_MEMBERS, ERROR_MEMBER, URL } from "../actions";

// get Members by department
export const getMembers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/members/`);
    dispatch({
      type: GET_MEMBERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: { msg: error.response, status: error.response },
    });
  }
};
// get Members by department
export const getMembersByDepartment = (_id) => async (dispatch) => {
  try {
    const res = await axios.get(`${URL}/api/members/department/${_id}`);
    dispatch({
      type: GET_MEMBERS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_MEMBER,
      payload: { msg: error.response, status: error.response },
    });
  }
};
