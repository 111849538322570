import React from "react";
import Header from "../components/Header";
import HeroImage from "../components/HeroImage";
import ServiceBox from "../components/ServiceBox";
import AboutUsTwo from "../components/AboutUs";
import CourseSlider from "../components/CourseSlider";
import NumberCounter from "../components/NumberCounter";
import FreeCourse from "../components/FreeCourse";
// import TeamSlider from "./components/TeamSlider";
import News from "../components/News";
import ImageGallery from "../components/ImageGallery";
import FooterTwo from "../components/Footer";
import TestimonialSlider from "../components/TestimonialSlider";

const Home = () => {
  return (
    <div className="main-wrapper">
      {/* Header 2 */}
      <Header />

      {/* Hero Image */}
      <HeroImage />

      {/* Service Box */}
      <ServiceBox />

      {/* About Us 2 */}
      <AboutUsTwo />
      {/* Testimonial Slider */}
      <TestimonialSlider />
      {/* Course Slider */}
      <CourseSlider />

      {/* Counter Area */}
      <NumberCounter />

      {/* Free Course Area */}
      <FreeCourse />

      {/* Team Slider */}
      {/* <TeamSlider /> */}

      {/* Blog Area */}
      <News />

      {/* Image Gallery Area */}
      {/* <ImageGallery /> */}

      {/* Footer 2 */}
      <FooterTwo />
    </div>
  );
};
export default Home;
