import { GET_GRADUTIONYEARS, ERROR_GRADUTIONYEAR } from "../actions";

const inialState = {
  graduationYears: [],
  graduationYear: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GRADUTIONYEARS:
      return {
        ...state,
        graduationYears: payload,
        loading: false,
      };
    case ERROR_GRADUTIONYEAR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
