import axios from "axios";
import { GET_GRADUTIONYEARS, URL, ERROR_GRADUTIONYEAR } from "../actions";

// GET Newss
export const getGraduationYears = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${URL}/api/graduationyear`);
    dispatch({
      type: GET_GRADUTIONYEARS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_GRADUTIONYEAR,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
