import axios from "axios";
import { GET_ADVS, ERROR_ADV, URL, GET_ADV } from "../actions";

// GET Newss
export const getAdvs = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`${URL}/api/advs`);
    dispatch({
      type: GET_ADVS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ADV,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};

// Get one News
export const getOneAdv = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`${URL}/api/advs/${id}`);
    dispatch({
      type: GET_ADV,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_ADV,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
