import React, { Component, useEffect } from "react";
import HeaderTwo from "../../components/HeaderTwo";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import AboutUs from "../../components/AboutUs";
import IconBox from "../../components/IconBox";
import TabBox from "./../../components/TabBox";
import Alumni from "./../../components/Alumni";
import FaqEvent from "../../components/FaqEvent";
import Footer from "../../components/Footer";
import { Styles } from "./styles/about.js";
import { getGraduationYears } from "../../redux/actions/gradutionsYear.action";
import { getStudents } from "../../redux/actions/Student.action";
import { useDispatch, useSelector } from "react-redux";
const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getGraduationYears());
    dispatch(getStudents());
  }, []);
  const { alumni } = useSelector((state) => state.students);
  const { graduationYears } = useSelector((state) => state.graduationYears);
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper about-page">
        {/* Header 2 */}
        <HeaderTwo />

        {/* Breadcroumb */}
        <BreadcrumbBox title="عن الجامعة" />

        {/* About Area */}
        <AboutUs />

        {/* Icon Box Area */}
        <IconBox />

        {/* Tab Section */}
        <TabBox />

        {/* Testimonial Slider */}
        {/* <TestimonialSlider /> */}

        {/* Faq & Event Area */}
        <FaqEvent />

        <Alumni alumni={alumni} graduationYears={graduationYears} />

        {/* Footer 2 */}
        <Footer />
      </div>
    </Styles>
  );
};

export default About;
