import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { URL } from "../../../redux/actions.js";
import { getNews } from "../../../redux/actions/news.action.js";
import { Styles } from "../styles/popularCourse.js";

const PopularCourse = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
  }, []);
  const { newss } = useSelector((state) => state.news);
  return (
    <Styles>
      {/* Popular Course */}
      <div className="popular-course">
        <h5> اخر الاخبار</h5>
        <div className="popular-items">
          {newss.slice(0, 6).map((data, i) => (
            <div className="item-box d-flex" key={i}>
              <div className="item-img">
                <Link to={`/events/${data._id}`}>
                  <img src={`${URL}${data.image}`} alt={data.title} />
                </Link>
              </div>
              <div className="item-content">
                <p className="title">
                  <Link to={`/events/${data._id}`}>{data.title}</Link>
                </p>{" "}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Styles>
  );
};

export default PopularCourse;
